import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

// resize last Width
var last_window_width = window.innerWidth;

// animations
const fades = document.querySelectorAll('[data-fade]')
fades.forEach(element => {
    gsap.to(element, {
        scrollTrigger: {
            trigger: element,
            start: "top 90%"
        },
        opacity: 1,
        y: 0,
        willChange: "transform",
        ease: "expo.out",
        delay: .2,
        duration: 1.6
    })
})
const scales = document.querySelectorAll('[data-scale]')
scales.forEach(element => {
    gsap.to(element, {
        scrollTrigger: {
            trigger: element,
            start: "top bottom"
        },
        scale: 1,
        ease: "expo.out",
        duration: 2.4
    })
})

// carousel
const carouselContainer = document.querySelector(['[data-carousel-container]'])
document.addEventListener('DOMContentLoaded', function () {
    if (carouselContainer) {
        initCarousel()
    }
})

function initCarousel() {
    const items = document.querySelectorAll('[data-carousel-item]')
    let active = document.querySelector('[data-carousel-container]').getAttribute('data-carousel-active') - 1
    let swipeDistance = 0
    let drag = false

    placeItems(active)

    items.forEach((item, i) => {
        item.addEventListener('click', () => {
            if (drag) return
            placeItems(i)
        })
    })

    window.addEventListener('resize', () => {
        var maxHeight = 0
        items.forEach(item => {
            maxHeight = item.scrollHeight > maxHeight ? item.scrollHeight : maxHeight
        })
        items.forEach((item, i) => {
            gsap.set(item, {
                x: (i - active) * items[active].getBoundingClientRect().width / gsap.getProperty(items[active], "scale"),
                force3D: true,
                minHeight: maxHeight
            })
        })
    })

    function moveItems(current) {
        const itemWidth = items[active].getBoundingClientRect().width / gsap.getProperty(items[active], "scale")

        items.forEach((item, i) => {
            gsap.set(item, {
                force3D: true,
                x: (i - current) * itemWidth
            })
        })

        items.forEach((item, i) => {
            ScrollTrigger.matchMedia({
                "(min-width: 768px)": () => {
                    gsap.set(item, {
                        scale: Math.max(.95 - Math.abs((i - current) * .1), .9),
                    })
                },
                "(max-width: 767.98px)": () => {
                    gsap.set(item, {
                        scale: .95
                    })
                },
            })
            if (i == Math.round(current)) {
                item.classList.add('!z-[1000]')
                if (item.querySelector('[data-button]')) {
                    item.querySelector('[data-button]').classList.remove('md:opacity-0', 'md:pointer-events-none')
                }
            } else {
                item.classList.remove('!z-[1000]')
                if (item.querySelector('[data-button]')) {
                    item.querySelector('[data-button]').classList.add('md:opacity-0', 'md:pointer-events-none')
                }
            }
        })
    }

    function placeItems(current) {
        const itemWidth = items[active].getBoundingClientRect().width / gsap.getProperty(items[active], "scale")
        var maxHeight = 0

        items.forEach(item => {
            maxHeight = item.scrollHeight > maxHeight ? item.scrollHeight : maxHeight
        })

        gsap.set(items, {
            minHeight: maxHeight
        })

        items.forEach((item, i) => {
            gsap.to(item, {
                force3D: true,
                x: (i - current) * itemWidth
            })
        })

        items.forEach((item, i) => {
            ScrollTrigger.matchMedia({
                "(min-width: 768px)": () => {
                    gsap.to(item, {
                        scale: Math.max(.95 - Math.abs((i - current) * .1), .9),
                    })
                },
                "(max-width: 767.98px)": () => {
                    gsap.to(item, {
                        scale: .95
                    })
                },
            })
            if (i == current) {
                item.classList.add('!z-[1000]')
                item.classList.remove('cursor-pointer')
                if (item.querySelector('[data-button]')) {
                    item.querySelector('[data-button]').classList.remove('md:opacity-0', 'md:pointer-events-none')
                }
            } else {
                item.classList.remove('!z-[1000]')
                item.classList.add('cursor-pointer')
                if (item.querySelector('[data-button]')) {
                    item.querySelector('[data-button]').classList.add('md:opacity-0', 'md:pointer-events-none')
                }
            }
        })

        active = current
    }
    
    carouselContainer.addEventListener('touchstart', (e) => {
        if (e.touches.length !== 1) return

        const startX = e.touches[0].pageX
        const slideWidth = items[active].getBoundingClientRect().width / gsap.getProperty(items[active], "scale")

        carouselContainer.addEventListener('touchmove', (e) => initTouchMove(e))

        function initTouchMove(e) {
            const swipeX = e.touches[0].pageX
            swipeDistance = ((startX - swipeX) / slideWidth / .8)

            moveItems(swipeDistance + active)
        }
    })

    carouselContainer.addEventListener('touchend', () => initTouchEnd())

    function initTouchEnd() {
        if (swipeDistance <= -.25) {
            active > 0 && active--
        }
        if (swipeDistance >= .25) {
            active < (items.length - 1) && active++
        }
        swipeDistance = 0
        placeItems(active)

        carouselContainer.removeEventListener('touchmove', (e) => initTouchMove(e))
        carouselContainer.removeEventListener('touchend', () => initTouchEnd())
    }

    var initMouseMove, initMouseEnd

    carouselContainer.addEventListener('mousedown', (e) => {
        drag = false
        e.stopPropagation();

        const startX = e.pageX
        const slideWidth = items[active].getBoundingClientRect().width / gsap.getProperty(items[active], "scale")

        initMouseMove = (e) => {
            drag = true
            const swipeX = e.pageX
            swipeDistance = ((startX - swipeX) / slideWidth / .8)

            moveItems(swipeDistance + active)
        }

        carouselContainer.addEventListener('mousemove', initMouseMove)
    
        initMouseEnd = () => {
            if (swipeDistance <= -.25) {
                active > 0 && active--
            }
            if (swipeDistance >= .25) {
                active < (items.length - 1) && active++
            }
            swipeDistance = 0
            placeItems(active)
    
            carouselContainer.removeEventListener('mousemove', initMouseMove)
            document.removeEventListener('mouseup', initMouseEnd, {
                once: true
            })
            document.removeEventListener('mouseleave', initMouseEnd, {
                once: true
            })
        }

        document.addEventListener('mouseup', initMouseEnd, {
            once: true
        })
        document.addEventListener('mouseleave', initMouseEnd, {
            once: true
        })
    })
}

// gallery
const galleryContainer = document.querySelector(['[data-gallery-container]'])
document.addEventListener('DOMContentLoaded', function () {
    if (galleryContainer) {
        initGallery()
    }
})

function initGallery() {
    const items = document.querySelectorAll('[data-gallery-item]')
    let active = document.querySelector('[data-gallery-container]').getAttribute('data-gallery-active') - 1
    let swipeDistance = 0

    placeItems(active)

    const buttons = document.querySelectorAll('[data-gallery-button]')
    buttons.forEach((button, i) => {
        button.addEventListener('click', () => {
            placeItems(i)
        })
    })

    items.forEach((item, i) => {
        item.addEventListener('click', () => {
            placeItems(i)
        })
    })

    function moveItems(current) {
        const itemWidth = items[active].getBoundingClientRect().width / gsap.getProperty(items[active], "scale")

        ScrollTrigger.matchMedia({
            "(min-width: 768px)": () => {
                items.forEach((item, i) => {
                    gsap.set(item, {
                        force3D: true,
                        x: 3 / 4 * (i - current) * itemWidth
                    })
                })
            },
            "(max-width: 767.98px)": () => {
                items.forEach((item, i) => {
                    gsap.set(item, {
                        force3D: true,
                        x: (i - current) * itemWidth
                    })
                })
            },
        })

        items.forEach((item, i) => {
            ScrollTrigger.matchMedia({
                "(min-width: 768px)": () => {
                    gsap.set(item, {
                        scale: Math.max(1 - Math.abs((i - current) * .25), 0),
                        opacity: 1 - Math.abs((i - current) * .5)
                    })
                },
                "(max-width: 767.98px)": () => {
                    gsap.set(item, {
                        scale: .95,
                        opacity: 1
                    })
                },
            })
            if (i == Math.round(current)) {
                item.classList.add('!z-[1000]')
                item.classList.remove('cursor-pointer')
                if (item.querySelector('[data-button]')) {
                    item.querySelector('[data-button]').classList.remove('md:opacity-0', 'md:pointer-events-none')
                }
                if (item.querySelector('p')) {
                    item.querySelector('p').classList.remove('opacity-0', 'pointer-events-none')
                }
            } else {
                item.classList.remove('!z-[1000]')
                item.classList.add('cursor-pointer')
                if (item.querySelector('[data-button]')) {
                    item.querySelector('[data-button]').classList.add('md:opacity-0', 'md:pointer-events-none')
                }
                if (item.querySelector('p')) {
                    item.querySelector('p').classList.add('opacity-0', 'pointer-events-none')
                }
            }
        })
    }

    function placeItems(current) {
        const itemWidth = items[active].getBoundingClientRect().width / gsap.getProperty(items[active], "scale")

        ScrollTrigger.matchMedia({
            "(min-width: 768px)": () => {
                items.forEach((item, i) => {
                    gsap.to(item, {
                        force3D: true,
                        x: 3 / 4 * (i - current) * itemWidth
                    })
                })
            },
            "(max-width: 767.98px)": () => {
                items.forEach((item, i) => {
                    gsap.to(item, {
                        force3D: true,
                        x: (i - current) * itemWidth
                    })
                })
            },
        })

        items.forEach((item, i) => {
            ScrollTrigger.matchMedia({
                "(min-width: 768px)": () => {
                    gsap.to(item, {
                        scale: Math.max(1 - Math.abs((i - current) * .25), 0),
                        opacity: 1 - Math.abs((i - current) * .5)
                    })
                },
                "(max-width: 767.98px)": () => {
                    gsap.to(item, {
                        scale: .95,
                        opacity: 1
                    })
                },
            })
            if (i == current) {
                item.classList.add('!z-[1000]')
                item.classList.remove('cursor-pointer')
                if (item.querySelector('[data-button]')) {
                    item.querySelector('[data-button]').classList.remove('md:opacity-0', 'md:pointer-events-none')
                }
                if (item.querySelector('p')) {
                    item.querySelector('p').classList.remove('opacity-0', 'pointer-events-none')
                }
            } else {
                item.classList.remove('!z-[1000]')
                item.classList.add('cursor-pointer')
                if (item.querySelector('[data-button]')) {
                    item.querySelector('[data-button]').classList.add('md:opacity-0', 'md:pointer-events-none')
                }
                if (item.querySelector('p')) {
                    item.querySelector('p').classList.add('opacity-0', 'pointer-events-none')
                }
            }
        })

        const buttons = document.querySelectorAll('[data-gallery-button]')
        buttons.forEach(buttonGeneral => {
            if (buttonGeneral === buttons[current]) return
            buttonGeneral.classList.remove('bg-black', 'text-white')
            buttonGeneral.classList.add('bg-white', 'text-copy')
        })
        buttons[current].classList.add('bg-black', 'text-white')
        buttons[current].classList.remove('bg-white', 'text-copy')

        active = current
    }

    galleryContainer.addEventListener('touchstart', (e) => {
        if (e.touches.length !== 1) return

        const startX = e.touches[0].pageX
        const slideWidth = items[active].getBoundingClientRect().width / gsap.getProperty(items[active], "scale")

        galleryContainer.addEventListener('touchmove', (e) => initTouchMove(e))

        function initTouchMove(e) {
            const swipeX = e.touches[0].pageX
            swipeDistance = ((startX - swipeX) / slideWidth / .8)

            moveItems(swipeDistance + active)
        }
    })

    galleryContainer.addEventListener('touchend', () => initTouchEnd())

    function initTouchEnd() {
        if (swipeDistance <= -.25) {
            active > 0 && active--
        }
        if (swipeDistance >= .25) {
            active < (items.length - 1) && active++
        }
        placeItems(active)

        galleryContainer.removeEventListener('touchmove', (e) => initTouchMove(e))
        galleryContainer.removeEventListener('touchend', () => initTouchEnd())
    }
}

// animation of trees
const trees = document.querySelectorAll('[data-name="tree"]')
trees.forEach(tree => {
    const wind = gsap.timeline({
        repeat: -1
    })
    wind.to(tree, {
        skewX: getRandomArbitrary(2, 4),
        transformOrigin: "bottom center",
        duration: 2.5,
        ease: "power3.easeIn"
    })
    wind.to(tree, {
        skewX: -getRandomArbitrary(2, 4),
        transformOrigin: "bottom center",
        duration: 5,
        ease: "power3.easeInOut"
    })
    wind.to(tree, {
        skewX: 0,
        transformOrigin: "bottom center",
        duration: 2.5,
        ease: "power3.easeOut"
    })
})

// parallax animation buildings
const parallaxs = document.querySelectorAll('[data-parallax-container]')
if (parallaxs) {
    gsap.to(parallaxs, {
        scale: 1,
        opacity: 1,
        ease: "expo.out",
        duration: 2.4,
        delay: .5,
        transformOrigin: "bottom center"
    })
    parallaxs.forEach(parallax => {
        const layers = parallax.querySelectorAll('[data-parallax-layer]')
        layers.forEach(layer => {
            gsap.to(layer, {
                scrollTrigger: {
                    start: "0px 0px",
                    end: `${window.innerHeight}px 0px`,
                    scrub: true
                },
                y: 20 * (layer.getAttribute('data-layer') - 1),
                transformOrigin: "bottom center",
                force3D: true
            })
        })
    })
}

// parallax animation overlays
const overlays = document.querySelectorAll('[data-hero-overlay]')
overlays.forEach(overlay => {
    gsap.set(overlay, {
        opacity: 0
    })
    gsap.to(overlay, {
        scrollTrigger: {
            start: "0px 0px",
            end: `${window.innerHeight}px 0px`,
            scrub: true
        },
        opacity: 1 - overlay.getAttribute('data-hero-overlay') / 20,
        force3D: true
    })
})

// clouds animation
const clouds = document.querySelectorAll('[data-hero-clouds] > div')
clouds.forEach(cloud => {
    gsap.to('[data-hero-clouds]', {
        scale: 1,
        opacity: 1,
        ease: "expo.out",
        duration: 2.4,
        delay: .5,
        transformOrigin: "bottom center"
    })
    gsap.set(cloud, {
        width: getRandomArbitrary(32, 96),
        height: getRandomArbitrary(32, 96),
        x: getRandomArbitrary(0, window.innerWidth),
        y: getRandomArbitrary(0, window.innerHeight / 2)
    })

    gsap.to(cloud, {
        scale: 1,
        x: getRandomArbitrary(-1, 1) > 0 ? -96 : window.innerWidth + 96,
        ease: "linear",
        duration: getRandomArbitrary(30, 60),
        repeat: -1,
        onComplete: () => {
            gsap.to(cloud, {
                scale: 0
            })
        }
    })
})

const innerClouds = document.querySelectorAll('[data-inner-hero-clouds] > image')
innerClouds.forEach(cloud => {
    gsap.to('[data-inner-hero-clouds]', {
        scale: 1,
        opacity: 1,
        ease: "expo.out",
        duration: 2.4,
        delay: .5,
        transformOrigin: "bottom center"
    })
    gsap.set(cloud, {
        width: getRandomArbitrary(32, 96),
        height: getRandomArbitrary(32, 96),
        x: getRandomArbitrary(0, window.innerWidth),
        y: getRandomArbitrary(0, window.innerHeight / 2)
    })

    gsap.to(cloud, {
        scale: 1,
        x: getRandomArbitrary(-1, 1) > 0 ? -96 : window.innerWidth + 96,
        ease: "linear",
        duration: getRandomArbitrary(30, 60),
        repeat: -1,
        onComplete: () => {
            gsap.to(cloud, {
                scale: 0
            })
        }
    })
})

// pipe animation
let pipes = document.querySelectorAll('[data-pipe]')
pipes.forEach(pipe => {
    for (let i = 0; i < 10; i++) {
        const clone = pipe.cloneNode(true)
        pipe.after(clone)
    }

    pipes = document.querySelectorAll('[data-pipe]')
})
pipes.forEach(pipe => {
    gsap.set(pipe, {
        xPercent: getRandomArbitrary(-10, 10),
        opacity: .5,
        scale: getRandomArbitrary(1, 2.5),
        transformOrigin: "bottom center"
    })

    gsap.to(pipe, {
        scale: 0,
        opacity: 1,
        yPercent: getRandomArbitrary(-750, -250),
        ease: "linear",
        duration: getRandomArbitrary(3, 8),
        repeat: -1,
        repeatDelay: getRandomArbitrary(0, 5)
    })
})

// bicycle animation
const bike = document.querySelector('#bicycle')
if (bike) {
    gsap.to(bike, {
        x: 300,
        duration: 12,
        ease: "linear",
        repeat: -1,
        repeatDelay: 5
    })
}

// dog animation
const dog = document.querySelector('#dog')
if (dog) {
    const ear01 = document.querySelector('#Group_52')
    const ear02 = document.querySelector('#Group_53')
    const ears = gsap.timeline({ repeat: -1, repeatDelay: 2, yoyo: true })

    ears.to(dog, {
        rotate: -10,
        y: 2,
        transformOrigin: "center center",
        duration: 2,
        ease: "ease.out"
    }, 0)
    ears.to(ear01, {
        rotate: 15,
        transformOrigin: "bottom left",
        duration: 2,
        ease: "ease.out"
    }, 0)
    ears.to(ear02, {
        rotate: 20,
        transformOrigin: "bottom left",
        duration: 2,
        ease: "ease.out"
    }, 0)
}

// objects hero animations
const objectTimeline = gsap.timeline({
    repeat: -1
})

window.addEventListener('scroll', () => {
    if (window.scrollY > window.innerHeight) {
        objectTimeline.pause()
    } else {
        objectTimeline.play()
    }
})

const objects = document.querySelectorAll("[data-hero-object]")
objects.forEach(object => {
    gsap.set(object, {
        x: object.hasAttribute('data-hero-to-right') ? -96 : window.innerWidth + 96
    })

    objectTimeline.fromTo(object, {
        scale: 0
    }, {
        scale: 1,
        duration: .3
    }, 0)

    objectTimeline.to(object, {
        x: object.hasAttribute('data-hero-to-left') ? -96 : window.innerWidth + 96,
        ease: "linear",
        duration: getRandomArbitrary(30, 60),
        repeat: -1
    }, 0)
})

// clip path main hero
const clipPathOld = document.querySelector('[data-clip-old]')
const clipPathNew = document.querySelector('[data-clip]')
document.addEventListener('DOMContentLoaded', function () {
    if (clipPathOld) {
        initHero()
    }
})

function initHero() {
    gsap.to(clipPathOld.querySelector('circle'), {
        attr: {
            r: Math.max(window.innerWidth, window.innerHeight * 1.5)
        },
        ease: "expo.out",
        duration: 5,
        delay: .2,
    })
    gsap.to(clipPathNew.querySelector('circle'), {
        scrollTrigger: {
            start: "64px 0px",
            toggleActions: "play none none reverse"
        },
        duration: 1,
        ease: "linear",
        attr: {
            r: Math.max(window.innerWidth, window.innerHeight * 2) * 2 / 3
        }
    })
}

// general
let menuStatus = 'hidden'
let menuInnerStatus = 'hidden'

// navbar
const navbar = document.querySelector('[data-navbar]')
const navbar_logo = document.querySelector('#navbar-logo')

// overlay
const overlay = document.querySelector('#overlay')

// inner menu
const arrow = document.querySelector('[data-inner-menu-arrow]')
const innerMenu = document.querySelector('[data-inner-menu]')
const menu = document.querySelector('[data-menu]')
const button = document.querySelector('[data-inner-menu-button]')

// claim hero
const claimHero = document.querySelector('[data-claim-hero]')

// accordion
let accordions = document.querySelectorAll('[data-accordion-container]')

// parallax
const parallax = document.querySelector('[data-parallax]')

// init onload
document.addEventListener('DOMContentLoaded', function () {
    initNavbar()
    initInnerMenu()
    initMobileMenu()
    initFormModal()
    // initFormClaims()

    if (parallax) {
        initParallax()
    }
    if (claimHero) {
        initClaimHero()
    }
    if (accordions) {
        initAccordions()
    }
})

function initParallax() {
    gsap.to('[data-parallax-middle]', {
        scrollTrigger: {
            start: "top top",
            end: `+=${window.innerHeight}`,
            scrub: true
        },
        yPercent: 2,
        scale: .98
    })
    gsap.to('[data-parallax-bottom]', {
        scrollTrigger: {
            start: "top top",
            end: `+=${window.innerHeight}`,
            scrub: true
        },
        yPercent: 4,
        scale: .96
    })
}

function initAccordions() {
    accordions.forEach(accordion => {
        const title = accordion.querySelector('[data-accordion-title]')
        const content = accordion.querySelector('[data-accordion-content]')
        const arrow = accordion.querySelector('[data-accordion-arrow]')

        title.addEventListener('click', () => {
            if (content.classList.contains('h-0')) {
                gsap.to(content, {
                    height: content.scrollHeight,
                    onComplete: () => {
                        content.classList.remove('h-0')
                    }
                })
                gsap.to(accordion, {
                    paddingBottom: '1.5rem'
                })
                arrow.classList.add('rotate-180', 'opacity-40')
            } else {
                gsap.to(content, {
                    height: 0,
                    onComplete: () => {
                        content.classList.add('h-0')
                    }
                })
                gsap.to(accordion, {
                    paddingBottom: '0'
                })
                arrow.classList.remove('rotate-180', 'opacity-40')
            }
        })
    })
}

function initClaimHero() {
    const tree = document.querySelector('[data-claim-hero-tree]')
    const finalScene = document.querySelector('[data-claim-hero-final]')

    ScrollTrigger.create({
        trigger: claimHero,
        start: "top bottom",
        onEnter: () => {
            gsap.fromTo(tree, {
                rotate: -10
            }, {
                rotate: 0,
                duration: 2,
                ease: "elastic.inOut(1, 0.999)"
            })
        },
        onLeaveBack: () => {
            gsap.to(tree, {
                rotate: -10,
                duration: 2,
                ease: "elastic.inOut(1, 0.999)"
            })
        }
    })

    gsap.to(finalScene, {
        clipPath: "polygon(0 0, 100% 0, 100% 100%, 0% 100%)",
        scrollTrigger: {
            trigger: claimHero,
            start: "top center",
            toggleActions: "play none none reverse"
        },
        duration: 2.6,
        ease: "expo.inOut"
    })
}

// hubspot form modal
function initFormModal() {
    const modal = document.querySelector('[data-form-modal]')
    const closeButton = document.querySelector('[data-close-form-button]')
    const initButtons = document.querySelectorAll('[data-init-form]')

    initButtons.forEach(button => {
        button.addEventListener('click', () => {
            modal.classList.remove('pointer-events-none', 'opacity-0')
            disableScroll()
        })
    })

    closeButton.addEventListener('click', () => {
        modal.classList.add('pointer-events-none', 'opacity-0')
        enableScroll()
    })
}

// claims form modal
function initFormClaims() {
    const modal = document.querySelector('[data-form-claims]')
    const closeButton = document.querySelector('[data-close-claims-button]')
    const initButtons = document.querySelectorAll('[data-init-claims]')

    initButtons.forEach(button => {
        button.addEventListener('click', () => {
            modal.classList.remove('pointer-events-none', 'opacity-0')
            disableScroll()
        })
    })

    closeButton.addEventListener('click', () => {
        modal.classList.add('pointer-events-none', 'opacity-0')
        enableScroll()
    })

    modal.addEventListener('submit', (e) => {
        e.preventDefault()
        submitFormAjax()
    })

    function submitFormAjax() {
        let xmlhttp= window.XMLHttpRequest ?
            new XMLHttpRequest() : new ActiveXObject("Microsoft.XMLHTTP");
    
        xmlhttp.onreadystatechange = function() {
            if (this.readyState === 4 && this.status === 200)
                document.querySelector('#claimsform').innerHTML = this.responseText; // Here is the response
        }
    
        let title = modal.querySelector('input[name="formData"]').value;
        let name = modal.querySelector('input[name="name"]').value;
        let email = modal.querySelector('input[name="email"]').value;
        let phone = modal.querySelector('input[name="phone"]').value;
        let policynumber = modal.querySelector('input[name="policynumber"]').value;
        let message = modal.querySelector('textarea[name="message"]').value;
    
        xmlhttp.open("POST","./js/send.php",true);
        xmlhttp.setRequestHeader("Content-type","application/x-www-form-urlencoded");
        xmlhttp.send("name=" + name + "&email=" + email + "&title=" + title + "&phone=" + phone + "&policynumber=" + policynumber + "&message=" + message);
    }
}

// mobile menu
function initMobileMenu() {
    const button = document.querySelector('[data-menu-button]')

    window.addEventListener('resize', () => initMenuResize())
    window.addEventListener('orientationchange', () => initMenuResize())

    button.addEventListener('click', () => {
        if (menuStatus === 'hidden') {
            showMenu()
            menuStatus = 'visible'
            disableScroll()
        } else {
            hideMenu()
            hideInnerMenu()
            menuStatus = 'hidden'
            menuInnerStatus = 'hidden'
            enableScroll()
        }
    })
}

function initMenuResize() {
    if (window.innerWidth >= 768) {
        if (menuInnerStatus === 'hidden') {
            enableScroll()
        }
    } else {
        if (menuStatus === 'hidden') {
            hideMenu()
            enableScroll()
        } else {
            disableScroll()
        }
    }
}

function showMenu() {
    const iconLine01 = document.querySelectorAll('[data-menu-button] div')[0]
    const iconLine02 = document.querySelectorAll('[data-menu-button] div')[1]
    iconLine01.classList.add('rotate-45', 'h-[3px]', 'translate-y-[4px]')
    iconLine02.classList.add('-rotate-45', 'h-[3px]', 'translate-y-[-4px]')
    menu.classList.remove('translate-x-full')
}

function hideMenu() {
    if (menu.classList.contains('translate-x-full')) return
    const iconLine01 = document.querySelectorAll('[data-menu-button] div')[0]
    const iconLine02 = document.querySelectorAll('[data-menu-button] div')[1]
    iconLine01.classList.remove('rotate-45', 'h-[3px]', 'translate-y-[4px]')
    iconLine02.classList.remove('-rotate-45', 'h-[3px]', 'translate-y-[-4px]')
    menu.classList.add('translate-x-full')
    button.classList.remove('text-black', 'before:block')
    button.classList.add('before:hidden')
}

function initInnerMenu() {
    ScrollTrigger.matchMedia({
        "(min-width: 768px)": function () {
            gsap.set(innerMenu, {
                yPercent: 10,
                y: 0,
                opacity: 0,
                maxHeight: "auto"
            })
        },
        "(max-width: 767.98px)": function () {
            gsap.set(innerMenu, {
                yPercent: 0,
                y: 0,
                opacity: 1,
                maxHeight: 0
            })
        }
    })

    button.addEventListener('click', () => {
        if (menuInnerStatus === 'hidden') {
            showMenu()
            showInnerMenu()
            menuStatus = 'visible'
            menuInnerStatus = 'visible'
            disableScroll()
            button.classList.add('text-black', 'before:block')
            button.classList.remove('before:hidden')
        } else {
            hideInnerMenu()
            menuInnerStatus = 'hidden'
            if (window.innerWidth >= 768) {
                enableScroll()
            }
            button.classList.remove('text-black', 'before:block')
            button.classList.add('before:hidden')
        }
    })

    if (overlay) {
        overlay.addEventListener('click', function () {
            enableScroll()
            if (arrow.classList.contains('rotate-180')) return
            arrow.classList.add('rotate-180')
            hideInnerMenu()
            hideMenu()
            menuInnerStatus = 'hidden'
            menuStatus = 'hidden'
        })
    }
}

function showInnerMenu() {
    arrow.classList.remove('rotate-180')
    ScrollTrigger.matchMedia({
        "(min-width: 768px)": function () {
            gsap.fromTo(innerMenu, {
                yPercent: 10,
                y: 0,
                opacity: 0
            }, {
                yPercent: 0,
                y: "100px",
                opacity: 1,
                ease: "expo.out",
                duration: .4,
                force3D: !0,
                overwrite: true,
                willChange: "transform"
            })
        },
        "(max-width: 767.98px)": function () {
            gsap.to(innerMenu, {
                y: 0,
                overwrite: true,
                maxHeight: innerMenu.scrollHeight,
                ease: "expo.out",
                duration: 1
            })
        }
    })
    innerMenu.classList.remove('md:pointer-events-none')
}

function hideInnerMenu() {
    arrow.classList.add('rotate-180')
    ScrollTrigger.matchMedia({
        "(min-width: 768px)": function () {
            gsap.fromTo(innerMenu, {
                yPercent: 0,
                y: "100px",
                opacity: 1
            }, {
                yPercent: 10,
                opacity: 0,
                y: "40px",
                ease: "expo.out",
                duration: .4,
                force3D: !0,
                overwrite: true,
                willChange: "transform"
            })
        },
        "(max-width: 767.98px)": function () {
            gsap.to(innerMenu, {
                y: 0,
                overwrite: true,
                maxHeight: 0,
                ease: "expo.out",
                duration: .3
            })
        }
    })
    innerMenu.classList.add('md:pointer-events-none')
}

// call this to Disable
function disableScroll() {
    document.body.classList.add('overflow-hidden')
    document.documentElement.classList.add('overflow-hidden')
    overlay.classList.add('bg-copy/20')
    overlay.classList.remove('pointer-events-none')
    objectTimeline.pause()
}

// call this to Enable
function enableScroll() {
    const modal = document.querySelector('[data-form-modal]')
    const claimsModal = document.querySelector('[data-form-claims]')
    objectTimeline.play()

    if (last_window_width !== window.innerWidth) {
        document.body.classList.remove('overflow-hidden')
        document.documentElement.classList.remove('overflow-hidden')
        overlay.classList.remove('bg-copy/20')
        overlay.classList.add('pointer-events-none')
        if (modal) {
            modal.classList.add('pointer-events-none', 'opacity-0')
        }
        if (claimsModal) {
            claimsModal.classList.add('pointer-events-none', 'opacity-0')
        }
    } else if (last_window_width === window.innerWidth) {
        // if (!modal || !claimsModal) return

        // if (modal.classList.contains('pointer-events-none', 'opacity-0') && claimsModal.classList.contains('pointer-events-none', 'opacity-0')) {
            document.body.classList.remove('overflow-hidden')
            document.documentElement.classList.remove('overflow-hidden')
            overlay.classList.remove('bg-copy/20')
            overlay.classList.add('pointer-events-none')
        // }
    }
    last_window_width = window.innerWidth
}


// init navbar animation
function initNavbar() {
    ScrollTrigger.matchMedia({
        "(min-width: 768px)": function () {
            navbar.classList.remove('navbar-scrolled')
            gsap.fromTo(navbar_logo, {
                y: "2.5rem",
                transformOrigin: "50% 50%",
                scale: 1.2
            }, {
                y: 0,
                scale: .8,
                scrollTrigger: {
                    start: `${window.innerHeight / 2 + 24}px 50%`,
                    end: "+=24",
                    scrub: .3,
                    onLeave: () => {
                        navbar.classList.add('navbar-scrolled')
                    },
                    onEnterBack: () => {
                        navbar.classList.remove('navbar-scrolled')
                    }
                }
            })
        },
        "(max-width: 767.98px)": function () {
            navbar.classList.remove('navbar-scrolled')
            gsap.set(navbar_logo, {
                scale: .8,
                transformOrigin: "0% 50%",
                y: 0
            })
            gsap.to(navbar_logo, {
                scrollTrigger: {
                    start: `${window.innerHeight / 2 + 10}px 50%`,
                    end: "+=10",
                    onLeave: () => {
                        navbar.classList.add('navbar-scrolled')
                    },
                    onEnterBack: () => {
                        navbar.classList.remove('navbar-scrolled')
                    }
                }
            })
        }
    })
}

// button animations
const buttons = document.querySelectorAll('[data-button]')
buttons.forEach(button => {
    button.addEventListener('pointerenter', () => {
        gsap.to(button, {
            scaleX: 1.03,
            scaleY: .98,
            duration: 1,
            ease: "elastic.out(1, 0.3)",
            force3D: !0
        })
    })
    button.addEventListener('pointerleave', () => {
        gsap.to(button, {
            scaleX: 1,
            scaleY: 1,
            duration: 1,
            ease: "elastic.out(1, 0.3)",
            force3D: !0
        })
    })
})

// random number Math
function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min
}

// api for careers page init
if (document.querySelector('#jobs')) {
    const api = `https://boards-api.greenhouse.io/v1/boards/maxinsurance/departments`

    async function postJobs(url = '') {
        const response = await fetch(url, {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify()
        })

        return response.json()
    }

    postJobs(api).then((data) => {
        const departmentsList = data.departments
        
        departmentsList.forEach(department => { 
            const jobsList = department.jobs

            if (jobsList.length !== 0) {
                const div = document.createElement('div')
                div.classList.add('mb-12', 'border-b-2', 'border-[#DDD]', 'overflow-hidden')
                // if (jobsList.length > 1) {
                //     div.setAttribute('data-accordion-container', '')
                // }
                div.setAttribute('data-fade', '')

                const title = document.createElement('div')
                title.classList.add('mb-4', 'relative')
                // title.setAttribute('data-accordion-title', '')

                const h3 = document.createElement('h3')
                h3.classList.add('text-[22px]', 'sm:text-[26px', 'md:text-[32px]', 'lg:text-[36px]', 'leading-[116%]', 'font-medium', 'mb-6')
                h3.innerText = department.name

                title.append(h3)
                // if (jobsList.length > 1) {
                //     title.append(arrow)
                // }
                div.append(title)

                const content = document.createElement('div')
                content.classList.add('flex', 'flex-col', 'gap-2')

                const link = document.createElement('a')
                link.classList.add('font-medium', 'text-[16px]', 'md:text-[18px]', 'leading-[166%]', 'pr-16', 'sm:pr-0', 'sm:max-w-[25em]', 'underline', 'w-fit', 'text-max-blue', 'hover:no-underline')
                link.innerText = jobsList[0].title
                link.href = jobsList[0].absolute_url

                const location = document.createElement('span')
                location.classList.add('font-medium', 'text-[16px]', 'leading-[150%]', 'tracking-[-0.02em]', 'mb-[1em]')
                location.innerText = jobsList[0].location.name

                content.append(link)
                content.append(location)  

                const contentInner = document.createElement('div')
                // if (jobsList.length > 1) {
                //     contentInner.classList.add('h-0', 'flex', 'flex-col', 'gap-2')
                //     contentInner.setAttribute('data-accordion-content', '')
                // } else {
                    contentInner.classList.add('flex', 'flex-col', 'gap-2')
                // }  

                jobsList.forEach((job, i) => {
                    if (i > 0) {
                        const link = document.createElement('a')
                        link.classList.add('font-medium', 'text-[16px]', 'md:text-[18px]', 'leading-[166%]', 'pr-16', 'sm:pr-0', 'sm:max-w-[25em]', 'underline', 'w-fit', 'text-max-blue', 'hover:no-underline')
                        link.innerText = job.title
                        link.href = job.absolute_url

                        const location = document.createElement('span')
                        location.classList.add('font-medium', 'text-[16px]', 'leading-[150%]', 'tracking-[-0.02em]', 'mb-[1em]')
                        location.innerText = job.location.name

                        contentInner.append(link)
                        contentInner.append(location)     
                    }

                    if (i < jobsList.length - 1) {
                        const divInner = document.createElement('div')
                        divInner.classList.add('mb-4', 'border-b-2', 'border-[#DDD]')
                        contentInner.append(divInner)
                    }
                })

                div.append(content)
                div.append(contentInner)

                document.querySelector("#jobs").append(div)

                accordions = document.querySelectorAll('[data-accordion-container]')

                gsap.to(div, {
                    opacity: 1,
                    y: 0,
                    willChange: "transform",
                    ease: "expo.out",
                    delay: .2,
                    duration: 1.6
                })
            }

            initAccordions()
        })
    })
}